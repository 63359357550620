import { FC, ReactNode } from 'react';
import { useRouter } from 'next/navigation';
import '../component.scss';
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaTiktok,
  FaPinterest,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { FOOTER_SOCIAL_LINKS } from '@/utils/constants';
import { Button } from '@headlessui/react';

interface ComponentProps {
  classNames?: string;
  children?: ReactNode;
}

export const SocialMediaLinks: FC<ComponentProps> = ({
  classNames,
  children,
}) => {
  const router = useRouter(),
    handleClick = (href: string, target: string) => {
      if (target === '_blank') {
        window.open(href);
      } else {
        router.push(href);
      }
    },
    socialLinks = Array.from(FOOTER_SOCIAL_LINKS),
    iconComponents = {
      FaFacebookF: FaFacebookF,
      FaInstagram: FaInstagram,
      FaYoutube: FaYoutube,
      FaTiktok: FaTiktok,
      FaXTwitter: FaXTwitter,
      FaPinterest: FaPinterest,
    };

  return (
    <>
      {socialLinks.map(([key, { href, icon }]) => {
        const IconComponent =
          iconComponents[icon as keyof typeof iconComponents];

        return (
          <Button
            className={classNames}
            key={key}
            onClick={() => handleClick(href, '_blank')}
          >
            {IconComponent && <IconComponent className='social-icon' />}
          </Button>
        );
      })}
    </>
  );
};
