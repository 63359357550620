import Link from 'next/link';
import { FC } from 'react';
import Button from '../common/Button';
import { BUTTON_TEXT } from 'utils/constants';

interface WhereToBuyBtnProps {
  isMobile: boolean;
  setMapOpen: (value: boolean) => void;
}

const WhereToBuyBtn: FC<WhereToBuyBtnProps> = ({ isMobile, setMapOpen }) => {
  if (isMobile) {
    return (
        <Link href='/locations'>
            <Button
                title={BUTTON_TEXT.shopNow.btnText}>
                {BUTTON_TEXT.shopNow.btnText}
            </Button>
        </ Link>
    )
  }
    return (
    <Button
        title={BUTTON_TEXT.shopNow.btnText}
        onClick={() => setMapOpen(true)}>
        {BUTTON_TEXT.shopNow.btnText}
    </Button>
  );
};

export default WhereToBuyBtn;
