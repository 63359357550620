import { createStore } from 'zustand/vanilla';
import { GeoLocation, MapData, SelectedPlace } from 'components/map/type';
import { BASE_URL, MAP_INITIAL_STATE, MAP_RADIAL_OPTIONS, MAP_RADIAL_SLUG, MAP_SLUG } from 'utils/constants';
import { OptionTypes, RadialOptionTypes } from 'types';

interface MapStoreState {
  modalMapOpen: boolean;
  locations: MapData[] | null;
  zoom: number;
  selectedKey: string | null;
  address: {
    selected: SelectedPlace;
  };
  hasLoaded: {
    initialLoad: boolean;
    partners: boolean;
    dispatch: boolean;
  };
  data: {
    dispatch: MapData[];
    partner: MapData[];
    list: MapData[];
  };
  distanceOptions: {
    selected: number | null;
  };
  mapCenter: {
    geometry: GeoLocation;
  };
  yourLocation: {
    lat: number,
    lng: number,
  },
  currentPage: {
    page: number;
  };
}

export type MapStoreActions = {
  setModalMapOpen: (value: boolean) => void;
  setLocations: (locations: MapData[] | null) => void;
  setSelectedKey: (selectedKey: string | null) => void;
  setRadiusData: (mapData: MapData[]) => void;
  setDefaultDistance: (option: number) => void;
  getSelectedDistance: (id?: number) => OptionTypes | null;
  setDistance: (id: number) => void;
  setSelectedDistance: (item: OptionTypes) => void;
  getDispatchData: () => Promise<MapData[]>;
  getPartnerData: (start: number, limit: number, allData: MapData[]) => Promise<MapData[]>;
  setMapCenter: (geometry: GeoLocation) => void;
  setCurrentPage: (page: number) => void;
  setYourLocation: (lat: number, lng: number) => void;
  setZoom: (zoom: number) => void;
  setSelected: (selected: SelectedPlace) => void; // Added as a standalone function
  findClosestStore : (userLocation: any, stores: MapData[] | any[], radius: number,updateStateStore: boolean
  ) => MapData[];
};

export type MapStore = MapStoreState & MapStoreActions;

export const createMapStore = (initState: MapStoreState) => {
  return createStore<MapStore>((set, get) => ({
    ...initState,
    setModalMapOpen: (modalMapOpen: boolean) => {
      set({ modalMapOpen });
    },
    setLocations: (locations: MapData[] | null) => {
      set({ locations });
    },
    setSelectedKey: (selectedKey: string | null) => {
      set({ selectedKey });
    },
    setSelected: (selected) => {
      set((state) => ({ address: { ...state.address, selected } }));
      set(() => ({ mapCenter: { geometry: selected.geometry } }));
      set(() => ({ currentPage: { page: 1 } }));
    },
    setZoom: (zoom: number) => {
      set({ zoom });
      set({ selectedKey: null });
    },
    setRadiusData: (mapData) => {
      const data = get().data;
      data.list = mapData;
      set({ data });
    },
    setDefaultDistance: (distanceOption) => {
      const distanceOptions = get().distanceOptions;
      distanceOptions.selected = distanceOption;

      set({ distanceOptions });
    },
    getSelectedDistance: () => {
      const distanceOptions = get().distanceOptions;
      if(distanceOptions.selected) return MAP_RADIAL_OPTIONS[distanceOptions.selected];
      return null;
    },
    setDistance: (id) => {
      set({
        distanceOptions: {
          selected: id,
        },
      });
    },
    setSelectedDistance: (item) => {
      const itemId = MAP_RADIAL_OPTIONS.findIndex(
        (MAP_RADIAL_OPTIONS) => MAP_RADIAL_OPTIONS.value === item.value
      );
      const mapCenter = get().mapCenter;
      const yourLocation = get().yourLocation;
      get().setDistance(itemId);

      set(() => ({
        mapCenter: {
          geometry: mapCenter.geometry.lat
            ? mapCenter.geometry
            : yourLocation,
        },
      }));
      const zoomValue = item as unknown as RadialOptionTypes;
      set({ zoom: zoomValue.zoom });
    },
    getDispatchData: async (): Promise<MapData[]> => {
      try {
        const latitude = get().mapCenter.geometry.lat;
        const longitude = get().mapCenter.geometry.lng;
        const distance = get().getSelectedDistance()?.value || 1000;
        const url = 
          BASE_URL + 
          MAP_RADIAL_SLUG + 
          `?latitude=` + 
          latitude + 
          `&longitude=` + 
          longitude +
          `&distance=` + 
          distance + 
          `&unit=mi`;
        const response = await fetch(url);
        const result = await response.json();
        const fetchedData = result.response.account_organizations;
        const processedData = fetchedData
        ?.filter((dealer: any) => dealer.organization_address?.longitude && dealer.organization_address?.latitude)
        ?.map((dealer: any, i: any) => ({
          key: `dispatch-${i}`,
          id: dealer.organization_id,
          dispatch: true,
          isOpenWindow: false,
          attributes: {
            longtitude: dealer.organization_address?.longitude,
            latitude: dealer.organization_address?.latitude,
            title: dealer.organization_name,
            address: dealer.organization_address?.street_1,
            description: '',
            phone: dealer.organization_phone_number,
            website: '',
            email: dealer.organization_email,
            organization: {
              external_id: dealer?.external_ids?.[0],
              name: dealer.organization_name,
            },
            job: {
              title: '',
              description: '',
              external_id: `ext${dealer?.external_ids?.[0]}`,
              address: {
                street_1: '',
                street_2: '',
                postal_code: '',
                city: null,
                state: null,
                country: null,
              },
              contacts: [],
            },
          },
        }));

        const { data } = get();
        data.dispatch = processedData;
        set({ data });
        return processedData;

      } catch (error) {
        console.error('Error fetching dispatch data:', error);
        return [];
      }
    },
    getPartnerData: async (
      start:number, 
      limit:number, 
      allData:MapData[],
    ):Promise<MapData[]> => {
      try {
          const url = 
          BASE_URL + MAP_SLUG + 
          `?pagination[start]=` + 
          start + `&pagination[limit]=` +
          limit + 
          `&populate[store_types][fields][0]=name`;

          const response = await fetch(url);
          const result = await response.json();
          const { data: fetchedData } = result;
          const processedData = fetchedData
          .filter((item: any) => item.attributes?.latitude && item.attributes?.longtitude)
          .map((item: any) => ({
            ...item,
            id: item.id,
            dispatch: item.dispatch,
            isOpenWindow: false,
            attributes: {
              ...item.attributes,
              latitude: Number(item.attributes.latitude),
              longtitude: Number(item.attributes.longtitude),
            },
          }));
  
          allData.push(...processedData);

          if (result.data.length === 0) {
            const { data, hasLoaded } = get();
            data.partner = allData;
            set({ data, hasLoaded });
            return allData;
          } else {
              return get().getPartnerData(start + limit, limit, allData);
          }
      } catch (error) {
          console.error("Error fetching data:", error);
          return allData;
      }
    },
    findClosestStore : (
      userLocation: any,
      stores: MapData[] | any[],
      radius: number,
      updateStateStore: boolean
    ): MapData[] => {
      const storesWithinRadius: any = [];
      stores
        ?.filter(
          (store) =>
            (store.attributes.latitude || store.attributes.longtitude)
        )
        ?.forEach((store) => {
          const storeLocation = new google.maps.LatLng(
            store.attributes.latitude,
            store.attributes.longtitude
          );
          const distance = google.maps.geometry.spherical.computeDistanceBetween(
            userLocation,
            storeLocation
          );
          if (distance <= radius * 1609.34) {
            storesWithinRadius.push(store);
          }
        });
  
      if (updateStateStore) {
        get().setRadiusData(storesWithinRadius);
      }
  
      return storesWithinRadius;
    },
    setMapCenter: (geometry) => {
      set({ mapCenter: { geometry } });
    },
    setCurrentPage: (page) => {
      set({ currentPage: { page } });
    },
    setYourLocation: (lat, lng) => {
      set({ 
        yourLocation: { 
          lat: lat, 
          lng: lng 
      }}),
      set({ mapCenter: { geometry: {lat: lat, lng: lng} } });

    },
  }));
};
export const useMapStore = createMapStore(MAP_INITIAL_STATE);